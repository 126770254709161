.winnerSlot {
  border-radius: 10px;
  background-color: #29579A;
  display: flex;
  overflow: hidden;
  position: relative;
}
.winnerSlotName {
  flex-basis: 100%;
  background-color: #ffcb05;
  align-items: center;
  font-weight: bold;
  font-size: 1.3rem;
  padding-left: 15px;
}
.winnerSlotNumber {
  flex-basis: 25%;
  display: flex;
  justify-content: flex-end;
  color: white;
  font-weight: bold;
  align-items: center;
  padding-right: 5px;
  font-size: 1.7rem;
}
.winnerSlotStart {
  position: absolute;
  width: 23px;
  height: 23px;
  left: 80%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.barPrize {
  width: 100%;
  height: 150px;
  display: flex;
  align-items: flex-end;
  position: relative;
}
.prizePosImg {
  position: absolute;
  transform: translate(-50%, -50%);
  max-width: 150px;
  height: 150px;
}
.prizePosText {
  position: absolute;
  transform: translate(-50%, -50%);
  max-width: 170px;
}
.prizeTextBox {
  border-radius: 10px;
  background-color: #29579A;
  border: 2px solid #29579A;
  display: flex;
  justify-content: center;
  color: white;
  font-weight: bold;
  font-size: 1rem;
  width: 130px;
  height: 25px;
  align-items: center;
}
.prizePosWinner {
  position: absolute;
  transform: translate(-50%, -50%);
}
.SpesialWinnerSlot {
  border-radius: 10px;
  display: flex;
  background-color: #ffcb05;
  align-items: center;
  overflow: hidden;
  height: 30px;
}
.spesialWinnerSlotName {
  width: 80%;
  color: #000000;
  font-weight: bold;
  text-align: center;
  font-size: 1.2rem;
  z-index: 99999;
}
.spesialWinnerSlotNumber {
  background-color: #29579A;
  width: 23%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 8px;
  height: 100%;
  color: white;
  font-weight: bold;
  font-size: 1.5rem;
}
.spesialWinnerSlotStart {
  position: absolute;
  width: 20px;
  height: 20px;
  left: 77%;
  top: 48%;
  transform: translate(-50%, -50%);
}
.specialHeaderWinner {
  position: relative;
  padding-top: 70px;
  margin-top: 20px;
}
.spesialCoin {
  width: 60px;
  height: 60px;
  position: absolute;
  top: 14%;
  left: 11%;
}
.spesialCoin:nth-child(2) {
  width: 60px;
  height: 60px;
  position: absolute;
  top: 14%;
  left: 28%;
}
.spesialCoin:nth-child(3) {
  width: 60px;
  height: 60px;
  position: absolute;
  top: 14%;
  left: 47%;
}
.spesialCoin:nth-child(4) {
  width: 60px;
  height: 60px;
  position: absolute;
  top: 14%;
  left: 65%;
}
.spesialCoin:nth-child(5) {
  width: 60px;
  height: 60px;
  position: absolute;
  top: 14%;
  left: 83%;
}