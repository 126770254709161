.containerCover {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-left: 3vw;
  padding-right: 3vw;
  min-height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}
.logoHeader {
  width: 100%;
}
.hariRayaWinnerSlot {
  background-color: #29579A;
  display: flex;
  overflow: hidden;
  position: relative;
  margin-block: 5px;
  border-radius: 7px;
  height: 30px;
}
.winnerSlotName {
  flex-basis: 75%;
  background-color: #ffcb05;
  padding: 5px;
  font-weight: bold;
  font-size: 1.5rem;
}
.winnerSlotNumber {
  flex-basis: 25%;
  display: flex;
  justify-content: flex-end;
  color: white;
  font-weight: bold;
  align-items: center;
  padding-right: 5px;
  font-size: 12px !important;
}
.winnerSlotStart {
  position: absolute;
  width: 40px;
  height: 40px;
  left: 75%;
  top: 45%;
  transform: translate(-50%, -50%);
}
.headerWinner {
  background-color: #29579A;
  padding: 5px;
  border-radius: 10px;
  color: #ffffff;
  text-align: center;
  font-weight: bold;
  text-align: center;
  font-size: 20px;
  margin-bottom: 10px;
}
