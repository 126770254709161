.footerContainer {
  margin-top: 20px;
}
.sponsorImage {
  width: 100%;
}
.sponsorButton {
  border-radius: 5px;
  background-color: #ffcb05;
  border: 2px solid #fff200;
  font-size: 1.3rem;
  color: black;
  font-weight: bold;
  font-family: "Arial Rounded MT Bold";
  text-align: center;
  cursor: pointer;
}
.sponsorCredit {
  margin-top: 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.sponsorCreditBot {
  padding: 10px;
  text-align: center;
}