.containerBulananWinner {
  display: flex;
  position: relative;
  border-radius: 5px;
  border-radius: 10px;
  background-color: #29579A;
  width: 200px;
  overflow: hidden;
  margin-block: 10px;
}
.bulananText {
  color: white;
  width: 150px;
  padding: 3px;
  padding-left: 25px;
  display: flex;
  font-size: 1.3rem;
  align-items: center;
  font-weight: bold;
}
.bulananNumber {
  background-color: #FAA619;
  padding: 3px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  color: black;
  font-weight: bold;
}
.bulananStar {
  position: absolute;
  transform: translate(-50%,-50%);
  left: 25%;
  top: 50%;
}